import { EnvType } from './type';

export const environment = {
  type: EnvType.dev2,
  socketUrl: 'https://main2.dev.wearewarp.com',
  backendUrl: 'https://main2.dev.wearewarp.com',
  authUrl: 'https://auth.dev.wearewarp.com',
  legacyDriverWebUrl: 'https://driver-web.dev.wearewarp.com',
  driverWebUrl: 'https://driver-web.dev.wearewarp.com',
  adminWebUrl: 'https://admin-2.dev.wearewarp.com',
  customerWebUrl: 'https://customer-2.dev.wearewarp.com',
  carrierWebUrl: 'https://carrier-2.dev.wearewarp.com',
  warehouseWebUrl: 'https://warehouse-2.dev.wearewarp.com',
  trackingWebUrl: 'https://tracking-2.dev.wearewarp.com',
  routingUrl: '',
  ratingUrl: '',
  routingAppUrl: '',
  fileServer: '',
  zipcodeUrl: 'https://gw.wearewarp.com/us-cities/v1/zipcode',
  twofaRequired: false,
  eventUrl: 'https://dev-event.wearewarp.link',
  routing2Url: '',
  planningUrl: '',
  dataorchUrl: '',
  wsUrl: '',
  restWarehouseBackendUrl: '',
  commlogServiceUrl: "https://commlog.dev.wearewarp.com",
  mapboxgl: {
    useProxy: true,
    apiUrl: 'https://mapbox.dev.wearewarp.com',
    eventUrl: 'https://mapbox.dev.wearewarp.com/events/v2',
  }
};
