import { ApiService } from "./api.service";

import { environment } from "@env/environment";

export class DataorchService {
    api: ApiService = null
    baseUrl: string = null

    constructor(api: ApiService) {
        this.api = api
        this.baseUrl = environment.dataorchUrl
    }

    public removeJob(jobId) {
        const url = `${this.baseUrl}/job/${jobId}/destroy`
        return this.api.POST(url)
    }

    public rollJob(jobId, days) {
        const url = `${this.baseUrl}/job/${jobId}/roll`
        return this.api.POST(url, {days})
    }

    public rollJobToDate(jobId, toDate) {
        const url = `${this.baseUrl}/job/${jobId}/roll-to-date`
        return this.api.POST(url, {toDate})
    }

    public rollShipmentToDate(id, toDate) {
        const url = `${this.baseUrl}/shipment/${id}/roll-to-date`
        return this.api.POST(url, {toDate})
    }

    public updateJobPickupTime(jobId, fromTime, toTime) {
        const url = `${this.baseUrl}/job/${jobId}/pickup-time`
        return this.api.PUT(url, {fromTime, toTime})
    }

    public putOnHold(jobId) {
        const url = `${this.baseUrl}/job/${jobId}/hold`
        return this.api.POST(url)
    }

    public unHold(jobId) {
        const url = `${this.baseUrl}/job/${jobId}/unhold`
        return this.api.POST(url)
    }

    public updateJobNotes(jobId, notes) {
        const url = `${this.baseUrl}/job/${jobId}/notes`
        return this.api.PUT(url, notes)
    }

    public updateJobRefNums(jobId, refNums) {
        const url = `${this.baseUrl}/job/${jobId}/refNums`
        return this.api.PUT(url, {refNums})
    }

    public updateJobInstructions(jobId, instructions) {
        const url = `${this.baseUrl}/job/${jobId}/instructions`
        return this.api.PUT(url, instructions)
    }


    public listShipmentPods(id) {
        const url = `${this.baseUrl}/shipment/${id}/pod`
        return this.api.GET(url)
    }

    public listShipmentItems(id) {
        const url = `${this.baseUrl}/shipment/${id}/item`
        return this.api.GET(url)
    }

    public updateShipmentMetadata(id, metadata) {
        const url = `${this.baseUrl}/shipment/${id}/metadata`
        return this.api.PUT(url, metadata)
    }

    public getBid(id) {
        const url = `${this.baseUrl}/carrier-bid/${id}`
        return this.api.GET(url)
    }

    public getJob(id) {
        const url = `${this.baseUrl}/job/${id}`
        return this.api.GET(url)
    }

    public getJobTasks(id) {
        const url = `${this.baseUrl}/job/${id}/tasks`
        return this.api.GET(url)
    }


    public loadBid(id) {
        const url = `${this.baseUrl}/carrier-bid/${id}/load`
        return this.api.GET(url)
    }

    public listClients() {
        const url = `${this.baseUrl}/client?limit=10000`
        return this.api.GET(url)
    }

    public getClient(id) {
        const url = `${this.baseUrl}/client/${id}`
        return this.api.GET(url)
    }

    public listClientWarehouses(id) {
        const url = `${this.baseUrl}/client/${id}/warehouse`
        return this.api.GET(url)
    }

    public listReportViews() {
        const url = `${this.baseUrl}/report-view`
        return this.api.GET(url)
    }

    public getReportViews(id) {
        const url = `${this.baseUrl}/report-view/${id}`
        return this.api.GET(url)
    }

    public listtReportTypes() {
        const url = `${this.baseUrl}/report-format`
        return this.api.GET(url)
    }

    public getReportFormat(id) {
        const url = `${this.baseUrl}/report-format/${id}`
        return this.api.GET(url)
    }

    public loadReport(type, filters, limit?) {
        const url = `${this.baseUrl}/report/${type}/data` + (limit ? `?limit=${limit}` : '')
        return this.api.POST(url, filters ?? {})
    }

    public loadReportCsv(type, filters) {
        const url = `${this.baseUrl}/report/${type}/csv`
        return this.api.POST(url, filters ?? {}, {responseType: 'text', observe: 'body'})
    }

    public listWarehouseJobs(warehouseId, shipmentIds) {
        const url = `${this.baseUrl}/warehouse/${warehouseId}/jobs`
        return this.api.POST(url, {ids: shipmentIds})
    }

    public listWarehouseTasks(warehouseId, shipmentIds) {
        const url = `${this.baseUrl}/warehouse/${warehouseId}/tasks`
        return this.api.POST(url, {ids: shipmentIds})
    }

    public search(key, types?) {
        const url = `${this.baseUrl}/search`
        return this.api.POST(url, {
            key,
            types: types ?? []
        })
    }
}